<template>
    <fieldset class="text-end" :disabled="loading" :class="{ 'mt-4': canDiscard || canEdit || canSend }">
        <button class="btn" @click="discard"
                v-if="canDiscard">Discard
        </button>
        <button class="btn" @click="edit"
                v-if="canEdit">Edit
        </button>
        <button class="btn btn-outline-secondary ms-2" @click="send"
                v-if="canSend">Confirm & Send
        </button>
    </fieldset>
</template>

<script>
import { mapState } from 'vuex';
import NotificationService from '@/services/notification.service';
import { AlertStatus } from '@/store/models/alert-status';

export default {
  props: ['alert'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      formAlertId: (state) => state.alerts.formAlertId,
    }),
    section() {
      return this.$route.params.section;
    },
    isNew() {
      return this.alert.$isNew;
    },
    isDraft() {
      return this.alert.status === AlertStatus.DRAFT;
    },
    canDiscard() {
      return this.isDraft && !this.isNew;
    },
    canEdit() {
      return !this.isNew && this.isDraft;
    },
    canSend() {
      return this.isDraft && !this.isNew;
    },
  },
  methods: {
    edit() {
      this.$store.commit('alerts/formAlertId', this.alert.id);
      this.$router.push(
        {
          name: 'alert',
          params: {
            section: this.section,
            id: this.alert.id,
          },
        },
      );
    },
    async discard() {
      this.loading = true;
      try {
        const res = await this.$store.dispatch('alerts/discardAlert', this.alert);
        NotificationService.success(res.message);
        this.getAlert();
      } finally {
        this.loading = false;
      }
    },
    async send() {
      const confirmed = await this.$bvModal.msgBoxConfirm('This will send alert to your subscribers.', {
        title: 'Send alert?',
        okTitle: 'Send',
        okVariant: 'btn-link btn-link--primary',
        cancelTitle: 'Cancel',
        cancelVariant: 'btn-link btn-link--primary',
        centered: true,
      });
      if (confirmed) {
        this.loading = true;
        try {
          const res = await this.$store.dispatch('alerts/sendAlert', this.alert);
          NotificationService.success(res.message);
          this.getAlert();
        } finally {
          this.loading = false;
        }
      }
    },
    getAlert() {
      this.$store.dispatch('alerts/getAlert', this.alert.id);
    },
  },
};
</script>
