<template>
    <div>
        <div class="row" :class="formAlertId ? 'justify-content-between' : 'justify-content-center'">
            <AlertsList :section="section" class="col-xxl-6 col-xl-7 pe-5" :class="formAlertId ? 'col-xl-7 col-lg-7' : 'col-12'"/>
            <transition name="slideLeftFade">
                <div class="col-lg-4 sidebar sidebar--right scrollbar" v-if="formAlertId">
                    <div class="d-flex justify-content-between align-items-center py-3">
                        <h5 class="mb-0">{{ isNew ? 'New alert' : `Alert` }}</h5>
                        <router-link :to="{ name: 'alerts', params: { section: 'drafts' } }" class="btn">
                        <span class="material-symbols-rounded align-middle">
                            close
                        </span>
                        </router-link>
                    </div>
                    <AlertForm class="mb-4"/>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import AlertForm from '@/components/alerts/AlertForm';
import AlertsList from '@/components/alerts/AlertsList';

export default {
  components: {
    AlertForm,
    AlertsList,
  },
  computed: {
    formAlertId() {
      return this.$route.params.id;
    },
    section() {
      return this.$route.params.section || 'drafts';
    },
    isNew() {
      return this.formAlertId === 'new';
    },
  },
};
</script>
