<template>
    <span :class="statusClass" :title="tooltip">
        {{ statusLabel }}
    </span>
</template>

<script>
import { AlertChannelStatusStatus } from '@/store/models/alert-channel-status-status';

export default {
  props: ['alert', 'channel'],
  computed: {
    channelStatusObj() {
      const channelStatus = this.alert.channel_statuses.find((item) => item.channel_id === this.channel.id);
      if (!channelStatus) {
        return null;
      }
      return channelStatus;
    },
    status() {
      if (this.channelStatusObj) {
        return this.channelStatusObj.status;
      }
      if (!this.channel.is_enabled) {
        return AlertChannelStatusStatus.DISABLED;
      }
      return AlertChannelStatusStatus.IN_PROGRESS;
    },
    tooltip() {
      // Other statuses have response content.
      const noResponseStatuses = [AlertChannelStatusStatus.IN_PROGRESS, AlertChannelStatusStatus.DISABLED];
      return !noResponseStatuses.includes(this.status)
        ? this.channelStatusObj.response_content : null;
    },
    statusLabel() {
      return AlertChannelStatusStatus.labels[this.status];
    },
    statusClass() {
      return AlertChannelStatusStatus.classes[this.status];
    },
  },
};
</script>
