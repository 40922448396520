export class AlertChannelStatusStatus {
  static IN_PROGRESS = 'IN_PROGRESS';

  static SENT = 'SENT';

  static ERROR = 'ERROR';

  static DISABLED = 'DISABLED'

  static labels = {
    [this.IN_PROGRESS]: 'In progress',
    [this.SENT]: 'Sent',
    [this.ERROR]: 'Error',
    [this.DISABLED]: 'Disabled',
  };

  static classes = {
    [this.IN_PROGRESS]: 'text-info',
    [this.SENT]: 'text-success',
    [this.ERROR]: 'text-danger',
    [this.DISABLED]: 'text-danger',
  }

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
