<template>
    <div v-if="alert">
        <div class="btn-group col-12 mb-3" role="group">
            <!--            <button type="button"-->
            <!--                    v-for="type in types"-->
            <!--                    class="btn"-->
            <!--                    @click="updateProp({type: type.id})"-->
            <!--                    :class="alert.type === type.id ? `btn-primary` : 'btn-secondary'">-->
            <!--                {{ type.name }}-->
            <!--            </button>-->
            <AppRadio class="mb-3"
                      :value="alert.type"
                      @input="updateProp({ type: $event})"
                      :options="types"
                      :errors="errors"
                      field="type"/>
        </div>
        <fieldset v-if="isPost">
            <AppInput label="Title"
                      :value="alert.title"
                      @input="updateProp({ title: $event })"
                      :errors="errors"
                      field="title"
                      placeholder="Title"
                      class="mb-3"/>
            <div class="mb-3">
                <VueEditor v-model="alertContent"/>
                <AppError :errors="errors" field="content"/>
            </div>
        </fieldset>
        <fieldset v-else>
            <!--            <AppRadio class="mb-3"-->
            <!--                      :value="alert.side"-->
            <!--                      @input="updateProp({ side: $event })"-->
            <!--                      :options="sides"-->
            <!--                      :errors="errors"-->
            <!--                      field="side"/>-->
            <div class="btn-group col-12 mb-3" role="group">
                <button type="button"
                        v-for="side in sides"
                        class="btn"
                        @click="updateProp({side: side.id})"
                        :class="alert.side === side.id ? `btn-${side.class}` : 'btn-secondary'">
                    {{ side.name }}
                </button>
            </div>
            <div class="row mb-3">
                <AppSelect :value="tickerPair"
                           :class="{
                         'is-invalid': errors.has('from_ticker') || errors.has('to_ticker')
                         }"
                           field="ticker"
                           :options="tickers"
                           track-by="pair"
                           label-field="pair"
                           @search-change="getTickers"
                           :loading="tickersLoading"
                           :internal-search="false"
                           @input="setTickers"
                           placeholder="Ticker">
                    <template slot="option" slot-scope="{ option }">
                        {{ option.pair }}
                        <small class="ps-2" v-if="option.exchange">{{ option.exchange }}</small>
                        <small class="ps-2" v-if="option.price">{{ option.price }}</small>
                    </template>
                    <template slot="noOptions">Type to search for tickers</template>
                </AppSelect>
                <AppError :errors="errors" :field="errors.has('from_ticker') ? 'from_ticker' : 'to_ticker'"/>
            </div>
            <div class="row mb-3">
                <AppInput label="Price"
                          :value="alert.price"
                          @input="updateProp({ price: $event })"
                          :errors="errors"
                          field="price"
                          type="number"
                          step="0.0001"
                          placeholder="Price"
                          class="col-7"/>
                <AppInput label="Size (%)"
                          :value="alert.portfolio_percentage"
                          @input="updateProp({ portfolio_percentage: $event })"
                          :errors="errors"
                          field="portfolio_percentage"
                          step="0.1"
                          type="number"
                          placeholder="Size (%)"
                          class="col-5"/>
            </div>
            <div class="row mb-3">
                <AppInput label="Stop"
                          :value="alert.stop"
                          @input="updateProp({ stop: $event })"
                          :errors="errors"
                          field="stop"
                          placeholder="Stop"
                          class="col-6"/>
                <AppInput label="Target"
                          :value="alert.target"
                          @input="updateProp({ target: $event })"
                          :errors="errors"
                          field="target"
                          placeholder="Target"
                          class="col-6"/>
            </div>
            <div class="row mb-3" v-if="isLimit">
                <AppInput label="Expiration date"
                          placeholder="Expiration date"
                          type="date"
                          class="col-6"/>
            </div>
            <VueEditor v-model="alertComment"/>
        </fieldset>
        <fieldset class="text-end" :disabled="loading">
            <button class="btn" @click="save">Save as draft</button>
            <button class="btn btn-primary ms-2" @click="saveAndSend">Send</button>
        </fieldset>
    </div>
</template>

<script>
import AppSelect from '@/components/form/AppSelect';
import AppInput from '@/components/form/AppInput';
import AppRadio from '@/components/form/AppRadio';
import Errors from '@/utils/errors';
import { mapGetters, mapState } from 'vuex';
import NotificationService from '@/services/notification.service';
import { AlertType } from '@/store/models/alert-type';
import { AlertSide } from '@/store/models/alert-side';
import { debounce } from 'lodash';
import AppError from '@/components/form/AppError';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    AppSelect,
    AppInput,
    AppRadio,
    AppError,
    VueEditor,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
    };
  },
  computed: {
    ...mapState({
      tickersLoading: (state) => state.tickers.loading,
      serviceId: (state) => state.products.serviceId,
    }),
    ...mapGetters({
      alert: 'alerts/formAlert',
      tickers: 'tickers/all',
    }),
    alertContent: {
      get() {
        return this.alert.content;
      },
      set(val) {
        this.updateProp({ content: val });
      },
    },
    alertComment: {
      get() {
        return this.alert.comment;
      },
      set(val) {
        this.updateProp({ comment: val });
      },
    },
    isNew() {
      return this.$route.params.id === 'new';
    },
    types() {
      return AlertType.options();
    },
    sides() {
      return AlertSide.options();
    },
    isLimit() {
      return this.alert.type === AlertType.LIMIT;
    },
    isPost() {
      return this.alert.type === AlertType.POST;
    },
    tickerPair() {
      if (!this.alert.from_ticker || !this.alert.to_ticker) {
        return null;
      }

      return {
        pair: `${this.alert.from_ticker}${this.alert.to_ticker}`,
      };
    },
  },
  mounted() {
    this.initAlert();
  },
  beforeDestroy() {
    this.$store.dispatch('alerts/discardNewAlert');
  },
  watch: {
    '$route.params.id'() {
      this.initAlert();
    },
  },
  methods: {
    setTickers(val) {
      if (!val) {
        return this.updateProp({
          from_ticker: null,
          to_ticker: null,
        });
      }
      return this.updateProp({
        from_ticker: val.from_ticker,
        to_ticker: val.to_ticker,
        price: val.price,
      });
    },
    async initAlert() {
      if (this.isNew) {
        await this.$store.dispatch('alerts/newAlert');
        await this.updateProp({ service_id: this.serviceId });
      } else {
        this.$store.commit('alerts/formAlertId', this.$route.params.id);
        this.getAlert();
      }
    },
    getAlert() {
      this.$store.dispatch('alerts/getAlert', this.$route.params.id);
    },
    getTickers: debounce(function getTickers(keyword) {
      if (keyword && keyword.length > 1) {
        this.$store.dispatch('tickers/getTickers', { keyword });
      }
    }, 300),
    updateProp(props) {
      this.$store.dispatch('alerts/alertProps', {
        props,
        alertId: this.alert.id,
      });
    },
    async saveAndSend() {
      const confirmed = await this.$bvModal.msgBoxConfirm('This will send alert to your subscribers.', {
        title: 'Send alert?',
        okTitle: 'Send',
        okVariant: 'btn-link btn-link--primary',
        cancelTitle: 'Cancel',
        cancelVariant: 'btn-link btn-link--primary',
        centered: true,
      });
      if (confirmed) {
        this.loading = true;
        this.errors.clear();

        try {
          let res = await this.$store.dispatch(this.isNew ? 'alerts/createAlert' : 'alerts/updateAlert', this.alert);
          res = await this.$store.dispatch('alerts/sendAlert', this.alert);
          NotificationService.success(res.message);
          await this.$store.dispatch('alerts/getAlert', res.alert_id);
          await this.$store.dispatch('alerts/newAlert');
        } catch (err) {
          this.errors.set(err.response.data.errors);
        } finally {
          this.loading = false;
        }
      }
    },
    async save() {
      this.loading = true;
      this.errors.clear();

      try {
        const res = await this.$store.dispatch(this.isNew ? 'alerts/createAlert' : 'alerts/updateAlert', this.alert);
        NotificationService.success(res.message);
        await this.$store.dispatch('alerts/getAlert', res.alert_id);
        await this.$store.dispatch('alerts/newAlert');
      } catch (err) {
        this.errors.set(err.response.data.errors);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
