<template>
    <div class="card border" :class="{ 'shadow-1 bg-light border-0': isSelected }">
        <div class="card-body">
            <h5 class="card-title mb-0">
                <template v-if="isPost">
                    {{ alert.title }}
                </template>
                <template v-else>
                    {{ alert.side }} Alert: {{ alert.from_ticker }}/{{ alert.to_ticker }}
                    <span class="text-lowercase" v-if="isLimit">({{ alert.type }})</span>
                </template>
            </h5>
            <address class="text-secondary small mb-1">
                <em><small v-if="creatorName">by {{ creatorName }} </small>
                    <time>
                        <small>
                            {{ alertDate }}
                        </small>
                    </time>
                </em>
            </address>
            <p class="mb-3" v-if="alert.service_id && product">
                <small>
                    <span class="badge rounded-pill text-bg-light">
                        {{ serviceLabel }}
                    </span>
                </small>
            </p>
            <div class="row" v-if="isPost">
                <div class="col alert__content" v-html="alert.content"/>
            </div>
            <div class="row" v-else>
                <ul class="list-unstyled col">
                    <li>
                        <small class="text-secondary">{{ isBuy ? 'Entry' : 'Exit' }}:</small><br>
                        ${{ alert.price | currency }} (~{{ alert.portfolio_percentage }}% of Portfolio)
                    </li>
                    <li v-if="alert.position_average_price && alert.position_percentage">
                        <small class="text-secondary">Average:</small><br>
                        ${{ alert.position_average_price | currency }} (~{{ alert.position_percentage }}% of Portfolio)
                    </li>
                    <li v-if="isLimit">
                        <small class="text-secondary">Expiration date:</small><br>
                        {{ alert.expires_at }}
                    </li>
                </ul>
                <ul class="list-unstyled col">
                    <li v-if="alert.stop">
                        <small class="text-secondary">Stop:</small><br>
                        ${{ alert.stop | currency }}
                    </li>
                    <li v-if="alert.target">
                        <small class="text-secondary">Target:</small><br>
                        ${{ alert.target | currency }}
                    </li>
                </ul>
                <ul class="list-unstyled col-12" v-if="alert.comment">
                    <li>
                        <small class="text-secondary">Comment:</small><br>
                        <div class="col alert__content" v-html="alert.comment"/>
                    </li>
                </ul>
            </div>
            <AlertChannelStatuses :alert="alert"/>
            <AlertListItemActions :alert="alert"/>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { formatCurrency } from '@/filters/currency.filter';
import { mapGetters, mapState } from 'vuex';
import { AlertType } from '@/store/models/alert-type';
import { AlertSide } from '@/store/models/alert-side';
import AlertChannelStatuses from '@/components/alerts/AlertChannelStatuses';
import AlertListItemActions from '@/components/alerts/AlertListItemActions';

export default {
  components: {
    AlertListItemActions,
    AlertChannelStatuses,
  },
  props: ['alert'],
  filters: {
    currency: formatCurrency,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      formAlertId: (state) => state.alerts.formAlertId,
    }),
    ...mapGetters({
      product: 'products/product',
    }),
    alertDate() {
      const format = 'MMM D, YYYY, h:mm a (UTC Z)';
      return this.alert && this.alert.created_at
        ? dayjs(this.alert.created_at)
          .format(format)
        : dayjs()
          .format(format);
    },
    isSelected() {
      return this.alert.id === this.formAlertId;
    },
    isLimit() {
      return this.alert.type === AlertType.LIMIT;
    },
    isPost() {
      return this.alert.type === AlertType.POST;
    },
    isBuy() {
      return this.alert.side === AlertSide.BUY;
    },
    isNew() {
      return this.$route.params.id === 'new';
    },
    creatorName() {
      if (this.isNew) {
        return this.user.name;
      }
      return this.alert.creator_name;
    },
    serviceLabel() {
      return this.product.services[this.alert.service_id];
    },
  },
};
</script>

<style>
.alert__content img {
    height: auto;
    max-width: 100%;
}
</style>
