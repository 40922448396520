<template>
    <ul v-if="isConfirmed && product" class="list-inline small text-secondary mb-0 text-end">
        <li class="list-inline-item pt-3 px-3" v-for="channel in product.alert_channels">
            <strong>{{ channel.name }}:</strong>
            <AlertChannelStatus :alert="alert" :channel="channel"/>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { AlertStatus } from '@/store/models/alert-status';
import AlertChannelStatus from '@/components/alerts/AlertChannelStatus';

export default {
  components: { AlertChannelStatus },
  props: ['alert'],
  computed: {
    ...mapGetters({
      product: 'products/product',
    }),
    isConfirmed() {
      return this.alert.status === AlertStatus.CONFIRMED;
    },
  },
};
</script>
