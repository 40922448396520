<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4 px-3">
            <h5 class=" mb-0 text-secondary">
                <span class="text-capitalize">{{ section }}</span> alerts
            </h5>
            <ServiceSelect @input="resetAlerts"/>
        </div>
        <transition name="slideLeftFade" mode="out-in">
            <EmptyState class="ms-3"
                        :content="`No ${section} alerts.`"
                        v-if="noAlerts"/>
            <transition-group name="slideLeftFade" v-else>
                <AlertsListItem class="mb-3"
                                v-for="alert in alerts"
                                :key="alert.id"
                                :alert="alert"/>
            </transition-group>
        </transition>
        <p class="text-center">
            <button class="btn btn-sm"
                    :disabled="isLoading"
                    v-if="!(allLoaded || noAlerts)"
                    @click="getAlerts">
                {{ isLoading ? 'Loading...' : 'Load more' }}
            </button>
        </p>
    </div>
</template>

<script>
import AlertsListItem from '@/components/alerts/AlertsListItem';
import { mapGetters, mapState } from 'vuex';
import EmptyState from '@/components/EmptyState';
import { AlertStatus } from '@/store/models/alert-status';
import ServiceSelect from '@/components/products/ServiceSelect';

export default {
  props: ['section'],
  components: {
    AlertsListItem,
    EmptyState,
    ServiceSelect,
  },
  computed: {
    ...mapState({
      allLoaded: (state) => state.alerts.allLoaded,
      isLoading: (state) => state.alerts.isLoading,
    }),
    ...mapGetters({
      alerts: 'alerts/all',
      product: 'products/product',
    }),
    noAlerts() {
      return !this.alerts || !this.alerts.length;
    },
  },
  watch: {
    '$route.params.section'() {
      this.setAlertFilter();
    },
  },
  mounted() {
    this.setAlertFilter();
    this.resetAlerts();
  },
  methods: {
    setAlertFilter() {
      const status = AlertStatus.routeMap[this.section];
      this.$store.commit('alerts/status', status);
    },
    getAlerts() {
      this.$store.dispatch('alerts/getAlerts');
    },
    resetAlerts() {
      this.$store.dispatch('alerts/resetAlerts');
    },
  },
};
</script>
